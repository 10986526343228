import {useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';

export default function TierListGameTile(props) {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging, isSorting} = useSortable({
    id: props.game.id,
    data: {type: "game"}
  });
  const style = {
    width: (props.tileSize) + "em",
    height: (props.tileSize) + "em",
    transform: isSorting ? undefined : CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? "0.5" : 1,
    touchAction: "none",
    pointerEvents: props.disabled ? "none" : undefined
  };

  return <div className="cover-shadow m-1 d-flex align-items-center justify-content-center" ref={setNodeRef} style={style} {...listeners} {...attributes}>
    <img className="mw-100 mh-100" src={`data:image/jpeg;base64,${props.game.cover}`} alt={props.game.name} title={props.game.name}/>
  </div>

}
