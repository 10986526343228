export default function TierListHeaderOverlay(props) {
  const style = {
    width: (props.tileSize + 1) + "em",
    height: (props.tileSize + 1) + "em",
    backgroundColor: props.tier.bgColor,
    color: props.tier.textColor,
    opacity: "0.8"
  };

  return <div className="d-flex align-items-center justify-content-center" style={style}>
    {props.tier.label}
  </div>

}
