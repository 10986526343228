import {CSS, useCombinedRefs} from '@dnd-kit/utilities';
import {InputText} from "primereact/inputtext";
import {ColorPicker} from "../../common/ColorPicker";
import {confirmDialog} from "primereact/confirmdialog";
import {useDraggable, useDroppable} from "@dnd-kit/core";
import {prefixTierId} from "../../utilities/CommonUtils";

export default function TierListHeaderTile(props) {
  const {setNodeRef: setDroppableNodeRef, isOver, over} = useDroppable({
    id: prefixTierId(props.tier.sortingUniqueId),
    data: {type: "tier"}
  });
  const {attributes, listeners, setNodeRef: setDraggableNodeRef, transform, isDragging} = useDraggable({
    id: prefixTierId(props.tier.sortingUniqueId),
    data: {type: "tier"}
  });
  const setNodeRef = useCombinedRefs(setDroppableNodeRef, setDraggableNodeRef);
  const style = {
    minWidth: "8em",
    width: (props.tileSize + 1) + "em",
    height: (props.tileSize + 1) + "em",
    backgroundColor: props.tier.bgColor,
    color: props.tier.textColor,
    transform: isDragging ? undefined : CSS.Transform.toString(transform),
    opacity: isDragging ? "0.5" : 1,
    touchAction: "none",
    outline: isOver && over.data.current.type === "tier" ? "2px dashed black" : undefined
  };

  return <div className="d-flex justify-content-start gap-2 position-relative" ref={setNodeRef} style={style}>
    <div className="d-flex flex-column justify-content-center gap-1 h-100" {...listeners} {...attributes}>
      <i className="pi pi-ellipsis-v"/>
      <i className="pi pi-ellipsis-v"/>
    </div>
    <div className="d-flex flex-column align-items-center justify-content-center gap-2">
      <InputText style={{borderColor: '#212529', backgroundColor: props.tier.bgColor, color: props.tier.textColor}} defaultValue={props.tier.label}
                 onBlur={event => {
                   props.tier.label = event.target.value;
                   if (props.tier.id !== props.unassignedTierId) {
                     props.tier.id = event.target.value || props.tier.id;
                   }
                   props.setTierListData([...props.tierListData]);
                 }} size={5} className="text-center"/>
      <div className="d-flex justify-content-center gap-2">
        <ColorPicker color={props.tier.bgColor} onChange={value => {
          props.tier.bgColor = value || props.tier.bgColor;
          props.setTierListData([...props.tierListData]);
        }}/>
        <ColorPicker color={props.tier.textColor} text="T" onChange={value => {
          props.tier.textColor = value || props.tier.textColor;
          props.setTierListData([...props.tierListData]);
        }}/>
      </div>
      <i className="pi pi-times position-absolute top-0 end-0 m-1" onClick={() => {
        confirmDialog({
          acceptLabel: 'Tak',
          rejectLabel: 'Nie',
          message: 'Czy na pewno usunąć tier ' + props.tier.label + '?',
          header: 'Potwierdź usuwanie',
          icon: 'pi pi-exclamation-triangle',
          acceptClassName: 'p-button-danger',
          accept: () => props.removeTier(props.tier.tierOrder)
        });
      }}/>
    </div>
  </div>

}
