import {useEffect, useMemo, useState} from 'react';
import {Panel} from "primereact/panel";
import {BlockUI} from "primereact/blockui";
import {Chart} from "primereact/chart";
import {DataLoadingIndicator} from "../../common/DataLoadingIndicator";
import {GAME_SOURCE} from "../../common/Enums";
import {arrayOfNumbers, isSmallScreen, isSmallScreenHorizontal, isSmallScreenVertical, updatePageTitle} from "../../utilities/CommonUtils";
import {StatsService} from "../../service/StatsService";
import {Dropdown} from "primereact/dropdown";
import {TOP_LIST_COLORS, YEAR_START} from "../../utilities/Constants";
import {useSessionStorage} from "primereact/hooks";

export default function Stats() {
  const title = 'Statystyki';
  const allStats = "Wszystkie";
  const emptyStats = useMemo(() => ({
    gamesAcquired: 0,
    gamesSold: 0,
    gamesOwned: 0,
    playCount: 0,
    averagePlayerCount: 0,
    uniqueGamesPlayed: 0,
    playsPerGameDaily: [],
    playsPerGameTotal: [],
    topPlayers: [],
    daysOfWeek: [],
    months: [],
    topDistinctGamesDaily: [],
    gameSources: [],
    newGamesByYear: [],
    ownedGamesByYear: [],
    coopResults: [],
    totalValueByYear: [],
    longestPlayingSeries: [],
    longestBreakSeries: [],
    publishers: []
  }), []);
  const [stats, setStats] = useState(emptyStats);
  const [year, setYear] = useSessionStorage(allStats, "stats.year");

  useEffect(() => {
    updatePageTitle(title);
  }, []);

  useEffect(() => {
    const storedYear = JSON.parse(sessionStorage.getItem("stats.year"));
    if (storedYear && storedYear !== year) return; // workaround for rendering old data
    if (year === allStats) {
      StatsService.getAll().then(data => setStats(data));
    } else {
      StatsService.get(year).then(data => setStats(data));
    }
  }, [year]);

  const headerTemplate = () => {
    return <div className={"p-panel-header bg-white" + (isSmallScreenVertical() ? " d-flex flex-column" : "")}>
      <h3>{title}</h3>
      <div>
        <Dropdown options={[allStats, ...arrayOfNumbers(YEAR_START, new Date().getFullYear()).reverse()]} value={year}
                  onChange={event => setYear(event.value)}/>
      </div>
    </div>
  }

  const chartColors = ['#ffbfe6', '#dda5e1', '#b7ceea', '#b6f1da', '#d6fc94', '#fff593', '#ffd6a6', '#ffc2b9'];
  const halfChartColors = chartColors.filter((value, index) => index % 2 === 0);
  const redAndGreen = ['#ffabab', '#d6fc94'];

  const pieChartOptions = {
    aspectRatio: 1.6,
    parsing: {
      key: 'value'
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          font: {
            size: 14
          }
        },
        position: 'right'
      }
    }
  };

  const barChartOptions = {
    plugins: {
      legend: {
        display: false,
      }
    }
  };

  const lineChartOptions = {
    plugins: {
      legend: {
        display: false,
      }
    }
  };

  const shortenWithTooltip = (text) => {
    if (text.length > 37) {
      return <span title={text}>{text.substring(0, 34) + "..."}</span>
    }
    return <span>{text}</span>
  }

  const singleValue = (title, value) => <div className="d-flex justify-content-between gap-5 border-bottom"><p className="fw-semibold">{title}</p><p>{value}</p>
  </div>;

  const topList = (title, dataList) => <div className="d-flex flex-column">
    <p className="text-center fw-semibold mb-0">{title}</p>
    <hr/>
    {dataList.map((entry, index) => <div className="d-flex justify-content-between gap-5" key={entry.key}>
      <p><i className="pi pi-star-fill me-2" style={{color: TOP_LIST_COLORS[index]}}/>{shortenWithTooltip(entry.key)}</p>
      <p>{entry.value}</p></div>)}
    <hr/>
  </div>;

  const pieChart = (title, dataList, labelMapping = (label => label), colors = chartColors) => dataList.length > 0 && <div className="d-flex flex-column">
    <p className="text-center fw-semibold mb-0">{title}</p>
    <hr/>
    <Chart type="pie" data={{
      labels: dataList.map(data => data.key).map(labelMapping),
      datasets: [{
        data: dataList,
        backgroundColor: colors
      }]
    }} options={pieChartOptions} width="350"/>
  </div>;

  const barChart = (title, dataList) => dataList.length > 0 && <div className="d-flex flex-column">
    <p className="text-center fw-semibold mb-0">{title}</p>
    <hr/>
    <Chart type="bar" data={{
      labels: dataList.map(data => data.key),
      datasets: [{
        data: dataList.map(data => data.value),
        backgroundColor: chartColors,
      }]
    }} options={barChartOptions} width="350"/>
  </div>;

  const lineChart = (title, dataList) => <div className="d-flex flex-column">
    <p className="text-center fw-semibold mb-0">{title}</p>
    <hr/>
    <Chart type="line" data={{
      labels: dataList.map(data => data.key),
      datasets: [{
        data: dataList.map(data => data.value),
        borderColor: chartColors,
      }]
    }} options={lineChartOptions} width="350"/>
  </div>;

  const structuredLayout = () => <>
    <div className="d-flex justify-content-center gap-5">
      <div className="d-flex gap-5">
        {singleValue('Pozyskane gry', stats.gamesAcquired)}
        {singleValue('Sprzedane gry', stats.gamesSold)}
        {singleValue('Posiadane gry', stats.gamesOwned)}
        {singleValue('Liczba rozgrywek', stats.playCount)}
        {singleValue('Średnia liczba graczy', stats.averagePlayerCount.toFixed(2))}
        {singleValue('Różne zagrane gry', stats.uniqueGamesPlayed)}
      </div>
    </div>
    <div className="d-flex justify-content-center gap-5">
      {topList('Najczęstsze gry (wg dni)', stats.playsPerGameDaily)}
      {topList('Najczęstsze gry (wg rozgrywek)', stats.playsPerGameTotal)}
      {topList('Najczęściej wygrywający gracze', stats.topPlayers)}
    </div>
    <div className="d-flex justify-content-center gap-5">
      {topList('Najwięcej różnych gier jednego dnia', stats.topDistinctGamesDaily)}
      {topList('Najdłuższe serie dni grania', stats.longestPlayingSeries)}
      {topList('Najdłuższe serie dni bez grania', stats.longestBreakSeries)}
    </div>
    <div className="d-flex justify-content-center gap-5">
      {pieChart('Najczęstsze dni tygodnia na granie', stats.daysOfWeek)}
      {pieChart('Najczęstsze miesiące na granie', stats.months)}
    </div>
    <div className="d-flex justify-content-center gap-5">
      {pieChart('Wyniki gier kooperacyjnych', stats.coopResults, label => label, redAndGreen)}
      {pieChart('Sposoby pozyskania gier', stats.gameSources, label => GAME_SOURCE[label], halfChartColors)}
      {pieChart('Gry wg wydawnictw', stats.publishers)}
    </div>
    <div className="d-flex justify-content-center gap-5">
      {year === allStats && barChart('Nowe gry w roku', stats.newGamesByYear)}
      {year === allStats && lineChart('Posiadane gry na koniec roku', stats.ownedGamesByYear)}
      {year === allStats && lineChart('Wartość kolekcji (zł)', stats.totalValueByYear)}
    </div>
  </>;

  const flatLayout = () => <div className={"d-flex flex-column gap-5" + (isSmallScreenHorizontal() ? " w-50 m-auto" : "")}>
    {singleValue('Pozyskane gry', stats.gamesAcquired)}
    {singleValue('Sprzedane gry', stats.gamesSold)}
    {singleValue('Posiadane gry', stats.gamesOwned)}
    {singleValue('Liczba rozgrywek', stats.playCount)}
    {singleValue('Średnia liczba graczy', stats.averagePlayerCount.toFixed(2))}
    {singleValue('Różne zagrane gry', stats.uniqueGamesPlayed)}
    {topList('Najczęstsze gry (wg dni)', stats.playsPerGameDaily)}
    {topList('Najczęstsze gry (wg rozgrywek)', stats.playsPerGameTotal)}
    {topList('Najczęściej wygrywający gracze', stats.topPlayers)}
    {topList('Najwięcej różnych gier jednego dnia', stats.topDistinctGamesDaily)}
    {topList('Najdłuższe serie dni grania', stats.longestPlayingSeries)}
    {topList('Najdłuższe serie dni bez grania', stats.longestBreakSeries)}
    {pieChart('Najczęstsze dni tygodnia na granie', stats.daysOfWeek)}
    {pieChart('Najczęstsze miesiące na granie', stats.months)}
    {pieChart('Wyniki gier kooperacyjnych', stats.coopResults, label => label, redAndGreen)}
    {pieChart('Sposoby pozyskania gier', stats.gameSources, label => GAME_SOURCE[label], halfChartColors)}
    {pieChart('Gry wg wydawnictw', stats.publishers)}
    {year === allStats && barChart('Nowe gry w roku', stats.newGamesByYear)}
    {year === allStats && lineChart('Posiadane gry na koniec roku', stats.ownedGamesByYear)}
    {year === allStats && lineChart('Wartość kolekcji (zł)', stats.totalValueByYear)}
  </div>

  return <>
    <Panel className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content stats-panel-content"}}}>
      <BlockUI blocked={stats === emptyStats} template={<DataLoadingIndicator/>} containerClassName="flex-grow-1">
        <div className="overflow-auto d-flex flex-column gap-5">
          {isSmallScreen() ? flatLayout() : structuredLayout()}
        </div>
      </BlockUI>
    </Panel>
  </>
}
