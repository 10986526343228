import {useEffect, useRef, useState} from 'react';
import {Tooltip} from 'react-tooltip';
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {FilterMatchMode} from "primereact/api";
import {Panel} from "primereact/panel";
import {Toast} from "primereact/toast";
import AuthWrapper from "../../common/AuthWrapper";
import {MultiSelect} from "primereact/multiselect";
import {Dialog} from "primereact/dialog";
import Legend from "../../common/Legend";
import Rating from "../../common/Rating";
import WaitListForm from "./WaitListForm";
import {DataLoadingIndicator} from "../../common/DataLoadingIndicator";
import {isSmallScreen, isSmallScreenVertical, minutesToFormattedTime, updatePageTitle} from "../../utilities/CommonUtils";
import {Divider} from "primereact/divider";
import {InputNumber} from "primereact/inputnumber";
import {Slider} from "primereact/slider";
import {ToggleButton} from "primereact/togglebutton";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {WaitListService} from "../../service/WaitListService";
import {useSessionStorage} from "primereact/hooks";
import {AutoComplete} from "primereact/autocomplete";

export default function GameList() {
  const title = 'Poczekalnia';
  const toast = useRef(null);
  const [showMoreFilters, setShowMoreFilters] = useSessionStorage(false, "waitlist.showMoreFilters");
  const [games, setGames] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [gameMoveToOwned, setGameMoveToOwned] = useState(null);
  const [editedGameId, setEditedGameId] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useSessionStorage('', "waitlist.globalFilterValue");
  const [globalFilterSuggestions, setGlobalFilterSuggestions] = useState([]);
  const [priceFilterFrom, setPriceFilterFrom] = useSessionStorage(null, "waitlist.priceFilterFrom");
  const [priceFilterTo, setPriceFilterTo] = useSessionStorage(null, "waitlist.priceFilterTo");
  const [playedFilter, setPlayedFilter] = useSessionStorage(null, "waitlist.playedFilter");
  const [wishlistFilter, setWishlistFilter] = useSessionStorage(true, "waitlist.wishlistFilter");
  const [sortField, setSortField] = useSessionStorage("ratingAverage", "waitlist.sortField");
  const [sortOrder, setSortOrder] = useSessionStorage(-1, "waitlist.sortOrder");
  const [resultCount, setResultCount] = useState(0);
  const [filters, setFilters] = useSessionStorage({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    price: {value: null, matchMode: FilterMatchMode.BETWEEN},
    played: {value: null, matchMode: FilterMatchMode.EQUALS},
    wishlist: {value: true, matchMode: FilterMatchMode.EQUALS}
  }, "waitlist.filters");
  const toggleableFilters = [
    {name: "price", label: "Cena"},
    {name: "played", label: "Zagrane"}
  ];
  const toggleableColumns = [
    {field: "minPlayers", header: "Min. graczy", sortable: true, filter: false, align: "center"},
    {field: "maxPlayers", header: "Max. graczy", sortable: true, filter: false, align: "center"},
    {field: "minMinutes", header: "Min. czas", sortable: true, filter: false, align: "center"},
    {field: "maxMinutes", header: "Max. czas", sortable: true, filter: false, align: "center"},
    {field: "price", header: "Cena", sortable: true, filter: true, align: "center"},
    {field: "remarks", header: "Komentarze", sortable: false, filter: false, align: "left", italic: true},
    {field: "played", header: "Zagrane", sortable: true, filter: true, align: "center"},
    {field: "ratingAnia", header: "Ocena Ani", sortable: true, filter: false, align: "center"},
    {field: "ratingTomek", header: "Ocena Tomka", sortable: true, filter: false, align: "center"},
    {field: "ratingAverage", header: "Ocena", sortable: true, filter: false, align: "center"}
  ]
  const defaultVisibleColumns = ["price", "remarks", "played", "ratingAnia", "ratingTomek", "ratingAverage"];
  const smallScreenVisibleColumns = ["ratingAverage"];
  const [visibleColumns, setVisibleColumns] = useSessionStorage(toggleableColumns.filter(column => {
    const columnSet = isSmallScreen() ? smallScreenVisibleColumns : defaultVisibleColumns;
    return columnSet.indexOf(column.field) !== -1;
  }), "waitlist.visibleColumns");
  const [visibleFilters, setVisibleFilters] = useSessionStorage(toggleableFilters.filter(filter => {
    return isSmallScreen() ? filter.name === "price" : true;
  }), "waitlist.visibleFilters");
  const onColumnToggle = (event) => {
    const selectedColumns = event.value;
    const orderedSelectedColumns = toggleableColumns.filter(column => selectedColumns.some((selectedColumn) => selectedColumn.field === column.field));
    setVisibleColumns(orderedSelectedColumns);
    event.stopPropagation();
  };
  const onFilterToggle = (event) => {
    const selectedFilters = event.value;
    const orderedSelectedFilters = toggleableFilters.filter(filter => selectedFilters.some((selectedFilter) => selectedFilter.name === filter.name));
    setVisibleFilters(orderedSelectedFilters);
    event.stopPropagation();
  };
  const minPrice = useRef(null);
  const maxPrice = useRef(null);
  const datatable = useRef(null);

  useEffect(() => {
    updatePageTitle(title);
  }, []);

  useEffect(() => {
    if (!showForm) {
      WaitListService.getAll().then(data => {
        setGames(data);
        minPrice.current = Math.min(...data.map(game => game.price));
        maxPrice.current = Math.max(...data.map(game => game.price));
      });
    }
  }, [showForm]);

  useEffect(() => {
    if (datatable.current !== null) {
      setResultCount(datatable.current.getTable().querySelectorAll('tbody.p-datatable-tbody tr:not(.p-datatable-emptymessage)').length);
    }
  }, [games]);

  const onGlobalFilterChange = (value) => {
    let _filters = {...filters};

    _filters['global'].value = value.trim();

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onPriceFilterChange = (from, to) => {
    const reset = from === null && to === null;
    const min = Math.min(from || minPrice.current, to || maxPrice.current);
    const max = Math.max(from || minPrice.current, to || maxPrice.current);
    setPriceFilterFrom(reset ? null : min);
    setPriceFilterTo(reset ? null : max);
    let _filters = {...filters};
    _filters['price'].value = reset ? null : [min, max];
    setFilters(_filters);
  };

  const onPlayedFilterChange = (value) => {
    setPlayedFilter(value);
    let _filters = {...filters};
    _filters['played'].value = value;
    setFilters(_filters);
  };

  const onWishlistFilterChange = (value) => {
    setResultCount(-1);
    setWishlistFilter(value);
    let _filters = {...filters};
    _filters['wishlist'].value = value;
    setFilters(_filters);
  };

  const resetAdvancedFilters = () => {
    onPriceFilterChange(null, null);
    onPlayedFilterChange(null);
  }

  const performRemove = async (id) => {
    WaitListService.delete(id).then(response => {
      if (response.ok) {
        let _games = [...games].filter(game => game.id !== id);
        setGames(_games);
        showSuccessToast('Gra została usunięta.');
      } else {
        response.text().then(error => showFailureToast('Gra nie została usunięta. ' + error));
      }
    });
  }

  const performBought = async (id, source) => {
    WaitListService.bought(id, source).then(response => {
      if (response.ok) {
        let _games = [...games].filter(game => game.id !== id);
        setGames(_games);
        showSuccessToast('Gra została przeniesiona do posiadanych gier.');
      } else {
        response.text().then(error => showFailureToast('Gra nie została przeniesiona. ' + error));
      }
      setGameMoveToOwned(null);
    });
  }

  const toggleForm = (id, show) => {
    setEditedGameId(id);
    setShowForm(show);
  }

  const changeWishlist = (game, value) => {
    const _game = {...game};
    _game.wishlist = value;
    _game.ratingAnia = _game.ratingAnia || 0;
    _game.ratingTomek = _game.ratingTomek || 0;
    WaitListService.save(_game).then(response => {
      if (response.ok) {
        showSuccessToast("Gra została przeniesiona.");
        let _games = [...games].map(game => game.id === _game.id ? _game : game);
        setGames(_games);
      } else {
        response.text().then(error => showFailureToast('Gra nie została przeniesiona. ' + error));
      }
    });
  }

  const coverTooltipTemplate = (game) => {
    return <span className="tooltip-cursor pi pi-image"
                 onMouseOver={(event) => {
                   if (!game.cover) {
                     WaitListService.getCover(game.id).then(data => {
                       game.cover = data.cover;
                       event.target.setAttribute("data-tooltip-html", `<img class="cover" src="data:image/jpeg;base64,${data.cover}" alt="${game.name + " (brak okładki)"}"/>`)
                     });
                   }
                 }}
                 data-tooltip-id="game-tooltip"
                 data-tooltip-html={`<img class="cover" src="data:image/jpeg;base64,${game.cover}" alt="${game.name + " (brak okładki)"}"/>`}></span>
  }

  const actionsTemplate = (game) => {
    return <AuthWrapper><span className="text-nowrap float-end">
      <Button size="small" severity="primary" className="me-1" icon="pi pi-heart-fill" hidden={wishlistFilter === true}
              onClick={() => changeWishlist(game, true)}/>
      <Button size="small" severity="primary" className="me-1" icon="pi pi-thumbs-down" hidden={wishlistFilter === false}
              onClick={() => changeWishlist(game, false)}/>
      <Button size="small" severity="primary" className="me-1" icon="pi pi-shopping-cart" onClick={() => setGameMoveToOwned(game)}/>
      <Button size="small" severity="primary" className="me-1" icon="pi pi-pencil" onClick={() => toggleForm(game.id, true)}/>
      <Button size="small" severity="danger" className="me-1" icon="pi pi-trash" onClick={() => confirmRemove(game.id, game.name)}/>
    </span></AuthWrapper>
  }

  const playedTemplate = (game) => {
    return game.played ? <i className="pi pi-check"/> : <i className="pi pi-times"/>;
  }

  const ratingTemplate = (rating) => {
    return rating ? <Rating value={rating} tooltipId={"game-tooltip"}/> : '';
  }

  const customBodyTemplates = {
    "played": playedTemplate,
    "ratingAnia": game => ratingTemplate(game.ratingAnia),
    "ratingTomek": game => ratingTemplate(game.ratingTomek),
    "ratingAverage": game => ratingTemplate(game.ratingAverage),
    "minMinutes": game => minutesToFormattedTime(game.minMinutes),
    "maxMinutes": game => minutesToFormattedTime(game.maxMinutes)
  };

  const waitListTypeButton = (wishlist) => {
    const label = wishlist ? "Lista życzeń" : "Oceny gier";
    const icon = wishlist ? "pi-heart-fill" : "pi-sort-alt";
    const count = wishlistFilter === wishlist && resultCount >= 0 ? " (" + resultCount + ")" : "";
    const severity = wishlistFilter === wishlist ? "primary" : "secondary";
    const className = isSmallScreenVertical() ? "me-2 mb-2" : "me-2";
    return <Button className={className} icon={"pi " + icon} severity={severity} label={label + count} style={{width: "11em"}}
                   onClick={() => onWishlistFilterChange(wishlist)}/>;
  };

  const headerTemplate = () => {
    return <>
      <div className={"p-panel-header bg-white" + (isSmallScreenVertical() ? " d-flex flex-column" : "")}>
        <div className="text-center">
          {waitListTypeButton(true)}
          {waitListTypeButton(false)}
        </div>
        <div className="d-flex justify-content-evenly">
          <AuthWrapper><Button className="me-2" icon="pi pi-plus-circle" tooltip="Dodaj grę" tooltipOptions={{position: "bottom"}}
                               onClick={() => toggleForm(null, true)}/></AuthWrapper>
          <Button className="me-2" icon="pi pi-question-circle" tooltip="Legenda" tooltipOptions={{position: "bottom"}}
                  onClick={() => setShowLegend(true)}/>
          <MultiSelect value={visibleColumns} options={toggleableColumns} optionLabel="header" onChange={onColumnToggle}
                       dropdownIcon="pi pi-rotate-90 pi-bars" className="me-2 icon-only" tooltip="Kolumny" tooltipOptions={{position: "bottom"}}/>
          <ToggleButton className="filter-toggle me-2" onLabel="" offLabel="" onIcon="pi pi-filter" offIcon="pi pi-filter"
                        tooltip="Dodatkowe filtrowanie" tooltipOptions={{position: "bottom"}}
                        checked={showMoreFilters} onChange={event => setShowMoreFilters(event.value)}/>
          <span className="p-input-icon-left">
            <i className="pi pi-search" style={{zIndex: 1}}/>
            <AutoComplete value={globalFilterValue} onChange={event => onGlobalFilterChange(event.target.value)} placeholder="Szukaj..."
                          inputClassName="w-100" inputStyle={{paddingLeft: "2.5rem"}} suggestions={globalFilterSuggestions}
                          completeMethod={event => setGlobalFilterSuggestions(games.map(game => game.name).filter(game => event.query ? game.toLowerCase().indexOf(event.query.toLowerCase()) !== -1 : true))}/>
            <i className="btn-close end-0 pt-0 pe-3" hidden={!globalFilterValue} onClick={() => onGlobalFilterChange("")}/>
          </span>
        </div>
      </div>
    </>
  }

  const closeForm = () => toggleForm(null, false);
  const showSuccessToast = (message) => toast.current.show({severity: 'success', summary: 'Sukces!', detail: message});
  const showFailureToast = (message) => toast.current.show({severity: 'error', summary: 'Błąd!', detail: message});

  const confirmRemove = (id, name) => {
    confirmDialog({
      acceptLabel: 'Tak',
      rejectLabel: 'Nie',
      message: 'Czy na pewno usunąć ' + name + '?',
      header: 'Potwierdź usuwanie',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: () => performRemove(id)
    });
  };

  const singleFilterClassName = filterName => visibleFilters.some(filter => filter.name === filterName) ? "d-flex align-items-center gap-3" : "d-none";

  const advancedFilters = <div className="d-flex align-items-center gap-4 horizontally-scrollable">
    <div className="d-flex align-items-center">
      <MultiSelect value={visibleFilters} options={toggleableFilters} optionLabel="label" onChange={onFilterToggle}
                   dropdownIcon="pi pi-sliders-h" className="me-2 icon-only" tooltip="Filtry" tooltipOptions={{position: "bottom"}}/>
      <Button icon="pi pi-filter-slash" tooltip="Resetuj filtrowanie" tooltipOptions={{position: "bottom"}} onClick={resetAdvancedFilters}/>
    </div>
    <div className={singleFilterClassName("price")}>
      <Divider layout="vertical" className="m-0"/>
      <span>Cena</span>
      <span>
        <span className="d-flex align-items-center">
          <InputNumber inputClassName="text-center" size={6} inputStyle={{height: "2rem"}} min={minPrice.current} max={maxPrice.current} value={priceFilterFrom}
                       onValueChange={event => onPriceFilterChange(event.value, priceFilterTo)}/>
          -
          <InputNumber inputClassName="text-center" size={6} inputStyle={{height: "2rem"}} min={minPrice.current} max={maxPrice.current} value={priceFilterTo}
                       onValueChange={event => onPriceFilterChange(priceFilterFrom, event.value)}/>
        </span>
        <Slider min={minPrice.current} max={maxPrice.current} range={true} value={[priceFilterFrom, priceFilterTo]}
                onChange={event => onPriceFilterChange(event.value[0], event.value[1])}/>
      </span>
    </div>
    <div className={singleFilterClassName("played")}>
      <Divider layout="vertical" className="m-0"/>
      <span>Zagrane</span>
      <TriStateCheckbox value={playedFilter} onChange={event => onPlayedFilterChange(event.value)}/>
    </div>
  </div>;

  return <>
    <Panel className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content"}}}>
      <Toast ref={toast} position="center" className="text-center"/>
      <ConfirmDialog/>
      <Dialog visible={gameMoveToOwned !== null} className="p-confirm-dialog" header="Potwierdź pozyskanie"
              footer={<div>
                <Button severity="text" label="Anuluj" onClick={() => setGameMoveToOwned(null)}/>
                <Button severity="primary" label="Zakup" onClick={() => performBought(gameMoveToOwned.id, 'PURCHASE')}/>
                <Button severity="primary" label="Prezent" onClick={() => performBought(gameMoveToOwned.id, 'GIFT')}/>
                <Button severity="primary" label="Konkurs" onClick={() => performBought(gameMoveToOwned.id, 'PRIZE')}/>
              </div>}
              onHide={() => setGameMoveToOwned(null)}>
        <span className="p-confirm-dialog-icon pi pi-question-circle"/>
        <span
          className="p-confirm-dialog-message">Przenieść <b>{gameMoveToOwned !== null ? gameMoveToOwned.name : ''}</b> z poczekalni do posiadanych gier?</span>
      </Dialog>
      <Dialog visible={showForm} resizable={false} showHeader={false} onHide={() => setShowForm(false)}>
        <WaitListForm id={editedGameId} onClose={closeForm} showFailureToast={showFailureToast} onSuccessfulSubmit={() => {
          closeForm();
          showSuccessToast('Zmiany zostały zapisane.');
        }}/>
      </Dialog>
      <Dialog visible={showLegend} header="Legenda ocen" onHide={() => setShowLegend(false)} modal={false}>
        <Legend/>
      </Dialog>
      <Tooltip id="game-tooltip" delayShow={500} place="right" className="tooltip-content"/>
      <DataTable ref={datatable} className="flex-grow-1" value={games} filters={filters} globalFilterFields={['name']}
                 onValueChange={(dataArray) => setResultCount(dataArray.length)}
                 sortField={sortField} sortOrder={sortOrder}
                 emptyMessage="Brak wyników."
                 scrollable scrollHeight="flex"
                 loading={games.length === 0} loadingIcon={<DataLoadingIndicator/>}
                 stripedRows
                 header={showMoreFilters ? advancedFilters : null}
                 onSort={event => {
                   setSortField(event.sortField);
                   setSortOrder(event.sortOrder);
                 }}>
        <Column field="cover" align="center" body={coverTooltipTemplate} hidden={isSmallScreen()}/>
        <Column field="name" header="Tytuł" sortable/>
        {visibleColumns.map(column =>
          <Column key={column.field} field={column.field} header={column.header} align={column.align}
                  sortable={column.sortable} filter={column.filter} showFilterMenu={false}
                  body={customBodyTemplates[column.field]} bodyClassName={column.italic ? "fst-italic" : ""}/>
        )}
        <Column body={actionsTemplate}/>
      </DataTable>
    </Panel>
  </>
}
